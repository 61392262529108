<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
        <div class="col-xl-10 m-auto">
          <h2 class="font-rubik">Our Services</h2>
        </div>
        <div class="col-xl-9 m-auto">
          <p class="font-rubik">We are a creative company that focuses on establishing long-term relationships with customers.</p>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>
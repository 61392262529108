<template>
  <div class="useable-tools-section bg-transparent mt-140 md-mt-80">
    <div class="container">
      <div class="title-style-three text-center mb-60 md-mb-50 sm-mb-30">
        <h6>Our Partners</h6>
        <h2>Who is
          <span>using deski <img src="../../assets/images/shape/line-shape-2.svg" alt=""></span>
          docs?
        </h2>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-9 m-auto">
          <p class="sub-text pb-80 md-pb-50">Deski ties into your existing tools, services, & workflow. Get
            notifications or create story with others tools.</p>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200">
          <div class="img-box bx-a">
            <a href="#"><img src="../../assets/images/logo/p-1.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
          <div class="img-box bx-b">
            <a href="#"><img src="../../assets/images/logo/p-2.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
          <div class="img-box bx-c">
            <a href="#"><img src="../../assets/images/logo/p-3.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
          <div class="img-box bx-d">
            <a href="#"><img src="../../assets/images/logo/p-4.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
          <div class="img-box bx-e">
            <a href="#"><img src="../../assets/images/logo/p-5.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-4 col-lg-2 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="250">
          <div class="img-box bx-f">
            <a href="#"><img src="../../assets/images/logo/p-6.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
          <div class="img-box bx-g">
            <a href="#"><img src="../../assets/images/logo/p-7.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-6" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="350">
          <div class="img-box bx-h">
            <a href="#"><img src="../../assets/images/logo/p-8.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
        <div class="col-xl-3 col-lg-12 col-md-4 col-6 m-auto" data-aos="fade-up" data-aos-duration="1200"
          data-aos-delay="400">
          <div class="img-box bx-i">
            <a href="#"><img src="../../assets/images/logo/p-9.png" alt=""></a>
          </div> <!-- /.img-box -->
        </div>
      </div>
    </div> <!-- /.container -->
  </div>
</template>

<script>
export default {
  name: 'UseAbleTools'
}
</script>
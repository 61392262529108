<template>
  <div class="fancy-short-banner-thirteen mt-200 md-mt-130">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 m-auto">
            <div class="title-style-twelve">
              <h2><span>Try Deski Free <img src="../../assets/images/shape/line-shape-13.svg" alt=""></span></h2>
              <p class="pt-45 pb-50 text-dark">After your 14-day trial of our Professional plan, enjoy the Free version
                of deski– forever.</p>
              <a href="#" class="theme-btn-nine">Start Free Trial</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortBanner'
}
</script>
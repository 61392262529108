<template>
  <div class="clearfix">
    <!-- header area -->
    <HeaderThree />
    <!-- hero area start -->
    <div class="fancy-hero-five">
      <div class="bg-wrapper">
        <div class="container">
          <div class="text-center">
            <h1 class="heading">Changelog</h1>
            <p class="sub-heading space-xs">
              Find the template Changelog here.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- hero area end -->

    <!-- doc area start -->
    <div class="doc-container mt-70 sm-m0">
      <div class="container">
        <div class="row flex-xl-nowrap g-0">
          <!-- ****************************** DOC MAIN BODY ********************************* -->
          <main class="col-12 doc-main-body">
            <small class="mb-20 d-block"
              >Dates are formated in <code>dd/mm/yy</code></small
            >
            <h5 class="font-rubik mb-20">
              Release Date: <mark>29/07/2022</mark>
            </h5>
            <div class="mark-blue">
              <pre>New template release</pre>
            </div>
            <!-- /.mark-blue -->
          </main>
          <!-- /.doc-main-body -->
        </div>
      </div>
    </div>
    <!-- doc area end -->
  </div>
</template>

<script>
import HeaderThree from "../common/Headers/HeaderThree.vue";

export default {
  name: "ChangeLogMain",
  components: { HeaderThree },
};
</script>

<template>
  <Blog_v3_Main/>
</template>

<script>
import Blog_v3_Main from '../components/blog-v3';

export default {
  name:'Blog_v3',
  components:{Blog_v3_Main}
}
</script>
<template>
  <LoginAreaMain/>
</template>

<script>
import LoginAreaMain from '../components/login';

export default {
  name:'LoginPage',
  components:{LoginAreaMain}
}
</script>
<template>
  <BlogDetailsMain/>
</template>

<script>
import BlogDetailsMain from '../../components/blog-details';

export default {
  name:'BlogDetails',
  components:{BlogDetailsMain}
}
</script>
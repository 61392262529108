<template>
  <Services_v2_Main/>
</template>

<script>
import Services_v2_Main from '../components/services-v2';

export default {
  name:'Services_v2',
  components:{Services_v2_Main}
}
</script>
<template>
  <div class="fancy-hero-five">
    <img src="../../assets/images/shape/93.svg" alt="" class="shapes shape-one">
    <img src="../../assets/images/shape/94.svg" alt="" class="shapes shape-two">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-8 col-sm-10">
            <h1 class="heading">
              <span>The Truste Docs <img src="../../assets/images/shape/line-shape-11.svg" alt=""></span>
              site ever.
            </h1>
            <p class="sub-heading">GitBook started mid-2014 as an open-source tool for developers to build
              documentation. Today we empower teams of all sizes to build great internal & external doc</p>
          </div>
          <div class="col-lg-5 col-md-4 position-relative">
            <img src="../../assets/images/media/img_52.png" alt="" class="img-meta">
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>
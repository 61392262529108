<template>
  <ContactEventMain/>
</template>

<script>
import ContactEventMain from '../components/contact-us(event)';

export default {
  name:'ContactUsEvent',
  components:{ContactEventMain}
}
</script>
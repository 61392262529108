<template>
  <div class="fancy-feature-eleven pt-130 md-pt-80" id="product">
    <div class="inner-container">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-md-9 m-auto">
            <div class="title-style-six text-center">
              <h6>Our Product</h6>
              <h2>We’ve helping <span>customer</span> globally now.</h2>
            </div>
            <!-- /.title-style-six -->
          </div>
        </div>
      </div>

      <div class="block-style-sixteen" style="background: #ebf3f1">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                class="icon d-flex align-items-center justify-content-center"
                style="background: #deeae7"
              >
                <img src="../../assets/images/icon/73.svg" alt="" />
              </div>
              <h3 class="title">Mobile Application Design.</h3>
              <p>
                Commonly used in the graphic, print & publishing industris for
                previewing visual layout and mockups.
              </p>
              <a href="#"
                ><img src="../../assets/images/icon/72.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
        <img
          src="../../assets/images/assets/screen_02.png"
          alt=""
          class="shapes screen-one"
        />
      </div>
      <!-- /.block-style-sixteen -->

      <div class="block-style-sixteen" style="background: #edf3f9">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                class="icon d-flex align-items-center justify-content-center"
                style="background: #e1e7ed"
              >
                <img src="../../assets/images/icon/74.svg" alt="" />
              </div>
              <h3 class="title">Branding & Design system.</h3>
              <p>
                Commonly used in the graphic, print & publishing industris for
                previewing visual layout and mockups.
              </p>
              <a href="#"
                ><img src="../../assets/images/icon/72.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
        <img
          src="../../assets/images/assets/screen_03.png"
          alt=""
          class="shapes screen-two"
        />
      </div>
      <!-- /.block-style-sixteen -->

      <div class="block-style-sixteen" style="background: #f9f1ef">
        <div class="container">
          <div class="row">
            <div
              class="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                class="icon d-flex align-items-center justify-content-center"
                style="background: #ede5e3"
              >
                <img src="../../assets/images/icon/75.svg" alt="" />
              </div>
              <h3 class="title">Custome & Plugin Development.</h3>
              <p>
                Commonly used in the graphic, print & publishing industris for
                previewing visual layout and mockups.
              </p>
              <a href="#"
                ><img src="../../assets/images/icon/72.svg" alt=""
              /></a>
            </div>
          </div>
        </div>
        <img
          src="../../assets/images/assets/screen_04.png"
          alt=""
          class="shapes screen-three"
        />
      </div>
      <!-- /.block-style-sixteen -->
    </div>
    <!-- /.inner-container -->
    <img
      src="../../assets/images/shape/139.svg"
      alt=""
      class="shapes shape-one"
    />
    <img
      src="../../assets/images/shape/140.svg"
      alt=""
      class="shapes shape-two"
    />
  </div>
</template>

<script>
export default {
  name: "FancyFeature",
};
</script>

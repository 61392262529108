<template>
  <div class="fancy-text-block-seven mt-150 md-mt-100">
    <div class="bg-wrapper">
      <img src="../../assets/images/shape/29.svg" alt="" class="shapes shape-one">
      <img src="../../assets/images/shape/30.svg" alt="" class="shapes shape-two">
      <img src="../../assets/images/shape/31.svg" alt="" class="shapes shape-three">
      <img src="../../assets/images/shape/32.svg" alt="" class="shapes shape-four">
      <img src="../../assets/images/shape/33.svg" alt="" class="shapes shape-five">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder">
              <img src="../../assets/images/media/img_19.png" alt="">
              <img src="../../assets/images/shape/34.svg" alt="" class="shapes shape-six">
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 ms-auto" data-aos="fade-left" data-aos-duration="1200">
            <div class="quote-wrapper pt-60">
              <img src="../../assets/images/icon/26.svg" alt="" class="icon">
              <blockquote class="font-rubik">
                Deski combines excellent live chat, ticketing and automation that allow us to provide quality.
              </blockquote>
              <h6>Zubayer Hasan <span>CEO & Founder deksi</span></h6>
              <router-link to="/about-us-cs" class="theme-btn-two mt-45 md-mt-30">Learn More</router-link>
            </div> <!-- /.quote-wrapper -->
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockTwo'
}
</script>
<template>
  <div class="fancy-text-block-seventeen mt-250 pt-20 md-mt-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 ms-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
          <div class="title-style-four">
            <h6>Our Story</h6>
            <div class="row">
              <div class="col-lg-12 col-md-8">
                <h2>We’r providing quality service
                  <span>since 2010!<img src="../../assets/images/shape/line-shape-2.svg" alt=""></span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-7 order-lg-first" data-aos="fade-right" data-aos-duration="1200">
          <p class="text-meta">Lorem ipsum dolor sit amet, consectetur adipiscing ed do eiusmod tempor inciddunt ut labore et dolore magna aliqua. </p>
          <p class="text-meta">quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor reprehen derit in voluptae velit esse cillum dolor fugia nul pariatur lorem itu.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextBlock'
}
</script>
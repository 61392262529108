<template>
  <NoteTakingMain/>
</template>

<script>
import NoteTakingMain from '../components/Note-taking';

export default {
  name:'NoteTaking',
  components:{
    NoteTakingMain
  }
}
</script>
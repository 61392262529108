<template>
  <PricingManagementMain/>
</template>

<script>
import PricingManagementMain from '../components/pricing(management)';

export default {
  name:'PricingManagement',
  components:{PricingManagementMain}
}
</script>
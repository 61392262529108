<template>
  <Blog_v2_Main/>
</template>

<script>
import Blog_v2_Main from '../components/blog-v2';

export default {
  name:'Blog_v2',
  components:{Blog_v2_Main}
}
</script>
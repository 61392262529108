<template>
  <div class="fancy-feature-thirtySeven lg-container pt-120" id="feature">
    <div class="container">
      <div class="row">
        <div class="col-xl-11 m-auto" data-aos="fade-up">
          <div class="title-style-fifteen text-center">
            <div class="upper-title">FEATURES</div>
            <h2>Complete Headset Filled with Unique Experiences.</h2>
          </div>
        </div>
      </div>
      <div class="img-meta mt-80 mb-65 md-mt-50 md-mb-50" data-aos="fade-up">
        <img src="../../assets/images/media/img_118.jpg" alt="" class="w-100"></div>
      <div class="d-flex align-items-center justify-content-center">
        <ul class="d-flex justify-content-center rating">
          <li><i class="fa fa-star" aria-hidden="true"></i></li>
          <li><i class="fa fa-star" aria-hidden="true"></i></li>
          <li><i class="fa fa-star" aria-hidden="true"></i></li>
          <li><i class="fa fa-star" aria-hidden="true"></i></li>
          <li><i class="fa fa-star" aria-hidden="true"></i></li>
        </ul>
        <div class="rating-count">5,638 REVIEWS</div>
      </div>
      <div class="row">
        <div class="col-xl-11 m-auto">
          <p class="text-lg text-center mt-30">DeskiVr is non-stop fun. The biggest titles and multi-player games—we
            have them. New ways to workout, socialize or lose track of time—we have those too. And our library keeps
            growing every day. Discover what’s possible on janovr.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesArea'
}
</script>
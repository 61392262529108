<template>
  <VR_LandingMain/>
</template>

<script>
import VR_LandingMain from '../components/vr-landing';

export default {
  name:'VR_Landing',
  components:{
    VR_LandingMain,
  }
}
</script>
<template>
  <PricingEventMain/>
</template>

<script>
import PricingEventMain from '../components/pricing(event)';

export default {
  name:'PricingEvent',
  components:{
    PricingEventMain
  }
}
</script>
<template>
  <ChangeLogMain/>
</template>

<script>
import ChangeLogMain from '../components/changelog';

export default {
  name:'ChangeLog',
  components:{ChangeLogMain}
}
</script>
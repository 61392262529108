<template>
  <AboutUsManagementMain/>
</template>

<script>
import AboutUsManagementMain from '../components/about-us(management)';

export default {
  name:'AboutUsManagement',
  components:{
    AboutUsManagementMain,
  }
}
</script>
<template>
  <Portfolio_v3_Main/>
</template>

<script>
import Portfolio_v3_Main from '../components/portfolio-v3';

export default {
  name:'Portfolio_v3',
  components:{
    Portfolio_v3_Main,
  }
}
</script>
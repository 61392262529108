<template>
  <div class="fancy-text-block-eight pt-150 pb-200 md-pt-100 md-pb-150">
    <div class="container">
      <div class="title-style-two text-center mb-150 md-mb-70">
        <div class="row">
          <div class="col-xl-8 col-lg-9 col-md-10 m-auto">
            <p>Features</p>
            <h2>Use deski to drive growth at
              <span>your business. <img src="../../assets/images/shape/line-shape-2.svg" alt=""></span>
            </h2>
          </div>
        </div>
      </div>

      <div class="block-style-six pb-150 md-pb-70">
        <div class="row">
          <div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
            <div class="text-details pt-35 md-pb-50">
              <img src="../../assets/images/icon/27.svg" alt="" class="icon">
              <h3 class="title font-gilroy-bold">Make communication Fast & efficient.</h3>
              <p class="text-meta">Our chatbots and live chat capture more ipsum of your best leads and convert them
                while they’re hot dummy text.</p>
              <div class="quote-wrapper">
                <div class="quote-icon d-flex align-items-center justify-content-center"><img
                    src="../../assets/images/icon/28.svg" alt=""></div>
                <blockquote>“Our team really feels great to use deski apps specially their quality”</blockquote>
                <div class="name"><strong>Micle Duke,</strong> Product Manager <br>Uber Inc.</div>
              </div> <!-- /.quote-wrapper -->
            </div> <!-- /.text-details -->
          </div>

          <div class="col-lg-7 col-md-9 m-auto" data-aos="fade-left" data-aos-duration="1200">
            <div class="illustration-holder illustration-one">
              <img src="../../assets/images/assets/feature-img-09.png" alt="" class="ms-auto">
              <div class="shapes shape-one"></div>
              <div class="shapes shape-two"></div>
              <div class="shapes shape-three"></div>
              <div class="shapes shape-four"></div>
              <div class="shapes shape-five"></div>
              <img src="../../assets/images/shape/35.svg" alt="" class="shapes shape-six">
              <img src="../../assets/images/shape/36.svg" alt="" class="shapes shape-seven">
            </div>
          </div>
        </div>
      </div> <!-- /.block-style-six -->

      <div class="block-style-six pt-150 md-pt-40">
        <div class="row">
          <div class="col-lg-5 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
            <div class="text-details pt-35 md-pb-50">
              <img src="../../assets/images/icon/29.svg" alt="" class="icon">
              <h3 class="title font-gilroy-bold">Friendly user interface & easy to use.</h3>
              <p class="text-meta">Deski stand with friendly interface with lots of features that help our team and
                csutomer to collbarate easily.</p>
              <div class="quote-wrapper">
                <div class="quote-icon d-flex align-items-center justify-content-center"><img
                    src="../../assets/images/icon/28.svg" alt=""></div>
                <blockquote>“Our team really feels great to use deski apps specially their quality”</blockquote>
                <div class="name"><strong>Micle Duke,</strong> Product Manager <br>Uber Inc.</div>
              </div> <!-- /.quote-wrapper -->
            </div> <!-- /.text-details -->
          </div>

          <div class="col-lg-7 col-md-9 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
            <div class="illustration-holder illustration-two">
              <img src="../../assets/images/assets/feature-img-10.png" alt="" class="me-auto">
              <div class="shapes shape-one"></div>
              <div class="shapes shape-two"></div>
              <div class="shapes shape-three"></div>
              <div class="shapes shape-four"></div>
              <img src="../../assets/images/shape/40.svg" alt="" class="shapes shape-five">
              <img src="../../assets/images/shape/41.svg" alt="" class="shapes shape-six">
            </div>
          </div>
        </div>
      </div> <!-- /.block-style-six -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeaturesArea'
}
</script>
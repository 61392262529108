<template>
  <div class="useable-tools-section-three mt-200 mb-200 md-mt-100 md-mb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="title-style-four">
            <h2>
              <span>Who is using ours 
                <img src="../../assets/images/shape/line-shape-10.svg" alt="">
              </span>
              deski docs?
            </h2>
          </div>
          <p class="sub-text">Deski ties into your existing tools, services, & workflow. Get notifications or create
            story with others tools.</p>
          <a href="#" class="all-button">See all partners <i class="flaticon-right-arrow"></i></a>
        </div>
      </div>
    </div> <!-- /.container -->
    <div class="logo-wrapper d-flex flex-wrap justify-content-center align-items-center">
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-32.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-33.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-34.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-35.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-36.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-37.png" alt="">
      </div>
      <div class="logo d-flex align-items-center justify-content-center">
        <img src="../../assets/images/logo/logo-38.png" alt="">
      </div>
    </div> <!-- /.logo-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'UseableTools'
}
</script>
<template>
  <div class="fancy-text-block-eleven pt-50">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
          <div class="text-wrapper ps-3">
            <div class="title-style-three mb-35 md-mb-20">
              <h6>Certified by trustpilot</h6>
              <h2><span>Certified and trusted <img src="../../assets/images/shape/line-shape-7.svg" alt="">
              </span>
                support portal.
              </h2>
            </div> <!-- /.title-style-two -->
            <p>Whether you're a theatre, stadium, intimate music venue, church, gala or concert hall, our seating chart tooi accurately represent your venue and seating layout.</p>
            <p class="mt-30">It's easy to incorporate socialy distanced spaces, add tables, edit the numbering or have.
            </p>
          </div>
        </div>
        <div class="col-lg-6 order-lg-first">
          <div class="img-gallery">
            <img src="../../assets/images/media/img_38.png" alt="" data-aos="fade-right" data-aos-duration="1200">
            <img src="../../assets/images/media/img_36.png" alt="" class="overlay-img" data-aos="fade-left" data-aos-duration="1200"
              data-aos-delay="100">
            <img src="../../assets/images/shape/49.svg" alt="" class="shapes shape-one">
            <img src="../../assets/images/shape/54.svg" alt="" class="shapes shape-three">
          </div> <!-- /.img-gallery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockThree'
}
</script>
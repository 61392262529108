<template>
  <DocBoxMain/>
</template>


<script>
import DocBoxMain from '../components/doc-box';

export default {
  name:'DocBox',
  components:{DocBoxMain}
}
</script>
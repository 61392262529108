<template>
  <div class="fancy-hero-six">
    <div class="container">
      <h1 class="heading">Grid Single</h1>
      <p class="sub-heading">
        An original way to show your works in a good appearance
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
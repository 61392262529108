<template>
  <div class="fancy-hero-three bg-transparent">
    <div class="shapes shape-one"></div>
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="shapes shape-four"></div>
    <div class="shapes shape-five"></div>
    <div class="shapes shape-six"></div>
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-10 col-md-10 m-auto">
            <h1 class="heading">Check our product in details</h1>
            <p class="sub-heading">deski helps teams of all sizes get better at delivering effortless customer</p>
            <a href="#" class="theme-btn-two trial-button mt-50 md-mt-30">Start Trial</a>
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>
<template>
  <div class="fancy-feature-fortyOne lg-container pt-180 md-pt-120">
    <div class="container">
      <div class="border-bottom pb-150 md-pb-80">
        <div class="row align-items-center">
          <div class="col-lg-6 order-lg-last" data-aos="fade-left">
            <div class="title-style-sixteen">
              <h2>Get Your VR Headset & start Rocking.</h2>
            </div>
            <p class="pe-xl-5 mt-30 mb-50">
              Enjoy exclusive premium concerts, movies, games and other exciting
              item.
            </p>
            <a href="#" class="buy-btn tran3s">BUY NOW</a>
          </div>
          <div class="col-lg-6" data-aos="fade-right">
            <img
              src="../../assets/images/media/img_124.png"
              alt=""
              class="md-mt-70"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- /.container -->
  </div>
</template>

<script>
export default {
  name: "FancyFeatureThree",
};
</script>

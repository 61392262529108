<template>
  <DocBoxBannerMain/>
</template>

<script>
import DocBoxBannerMain from '../components/doc-box-banner';

export default {
  name:'DocBoxBanner',
  components:{DocBoxBannerMain}
}
</script>
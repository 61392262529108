<template>
  <div class="fancy-feature-two">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-7 col-md-9 m-auto">
          <div class="title-style-one text-center mb-190 md-mb-100">
            <h2>Know everything about App features.</h2>
          </div>
          <!-- /.title-style-one -->
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row align-items-center block-style-two pb-250 md-pb-90 overflow-hidden">
        <div class="col-lg-7 col-md-8 m-auto">
          <div data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder img-holder-one">
              <div class="cs-screen progress-line-one" data-aos="fade-right" data-aos-duration="1200"></div>
              <div class="cs-screen progress-line-two" data-aos="fade-right" data-aos-duration="1200"></div>
              <img src="../../assets/images/assets/feature-img-05.png" alt="" />
              <img src="../../assets/images/shape/1.svg" alt="" class="cs-screen screen-one" />
              <img src="../../assets/images/shape/2.svg" alt="" class="cs-screen dot-shape" />
              <div class="bubble-one"></div>
              <div class="bubble-two"></div>
              <div class="bubble-three"></div>
              <div class="bubble-four"></div>
            </div>
            <!-- /.img-holder -->
          </div>
        </div>
        <div class="col-lg-5">
          <div class="text-wrapper" data-aos="fade-left" data-aos-duration="1200">
            <h6 class="font-rubik" style="color: #ff5291">FLEXIBILITY</h6>
            <h3 class="font-gilroy-bold">
              Get instant visibility into all your team work.
            </h3>
            <p>
              Don’t waste time on tedious manual tasks. Let Automation do it for
              you. Simplify workflows, reduce errors, and save time for solving
              more important problems.
            </p>
            <a href="#">Learn about flexibility</a>
          </div>
          <!-- /.text-wrapper -->
        </div>
      </div>
      <!-- /.block-style-two -->

      <div class="row align-items-center block-style-two pb-250 md-pb-90">
        <div class="col-lg-6 col-md-8 m-auto order-lg-last">
          <div data-aos="fade-left" data-aos-duration="1200">
            <div class="img-holder img-holder-two">
              <img src="../../assets/images/assets/feature-img-06.png" alt="" />
              <img src="../../assets/images/assets/feature-img-06-01.png" alt="" class="cs-screen screen-one" />
              <img src="../../assets/images/shape/3.svg" alt="" class="cs-screen screen-two" />
              <img src="../../assets/images/shape/4.svg" alt="" class="cs-screen dot-shape" />
              <div class="bubble-one"></div>
              <div class="bubble-two"></div>
              <div class="bubble-three"></div>
              <div class="bubble-four"></div>
            </div>
            <!-- /.img-holder -->
          </div>
        </div>
        <div class="col-lg-6 order-lg-first">
          <div class="text-wrapper ps-5 pe-5" data-aos="fade-right" data-aos-duration="1200">
            <h6 class="font-rubik" style="color: #aa4cff">QUICK & FAST</h6>
            <h3 class="font-gilroy-bold">Fast App response time</h3>
            <p>
              deski is 5x as fast to load than other popular tools, quick to
              configure.
            </p>
            <div class="quote">
              <blockquote>
                “Our team really feel great to use the deski app specially their
                faster load & server speed”
              </blockquote>
              <div class="d-flex align-items-center">
                <img src="../../assets/images/media/img_01.png" alt="" />
                <div class="info-meta">
                  <h5>Micle Duke, Product Manager</h5>
                  <span class="font-rubik">Uber Inc.</span>
                </div>
              </div>
            </div>
            <!-- /.quote -->
          </div>
          <!-- /.text-wrapper -->
        </div>
      </div>
      <!-- /.block-style-two -->

      <div class="row align-items-center block-style-two pb-170 md-pb-50">
        <div class="col-lg-7 col-md-7 m-auto">
          <div data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder img-holder-three">
              <img src="../../assets/images/assets/feature-img-07.png" alt="" />
              <img src="../../assets/images/assets/feature-img-07-01.png" alt="" class="cs-screen screen-one" />
              <img src="../../assets/images/shape/5.svg" alt="" class="cs-screen screen-two" />
              <img src="../../assets/images/shape/6.svg" alt="" class="cs-screen dot-shape" />
              <div class="bubble-one"></div>
              <div class="bubble-two"></div>
              <div class="bubble-three"></div>
              <div class="bubble-four"></div>
            </div>
            <!-- /.img-holder -->
          </div>
        </div>
        <div class="col-lg-5">
          <div class="text-wrapper" data-aos="fade-left" data-aos-duration="1200">
            <h6 class="font-rubik" style="color: #03ddd5">PROJECT STATUS</h6>
            <h3 class="font-gilroy-bold">
              Real time project status monitoring.
            </h3>
            <p>
              Don’t waste time on tedious manual tasks. Let Automation do it for
              you. Simplify workflows, reduce errors, and save time for solving
              more important problems.
            </p>
          </div>
          <!-- /.text-wrapper -->
        </div>
      </div>
      <!-- /.block-style-two -->
    </div>
    <!-- /.container -->
  </div>
</template>

<script>
export default {};
</script>
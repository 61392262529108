<template>
  <Blog_v1_Main/>
</template>

<script>
import Blog_v1_Main from '../components/blog-v1';

export default {
  name:'Blog_v1',
  components:{Blog_v1_Main},
}
</script>
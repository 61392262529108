<template>
  <div class="fancy-hero-two">
    <div class="bg-wrapper">
      <div class="container">
        <div class="page-title">About us</div>
        <div class="row">
          <div class="col-xl-8 col-lg-10 col-md-10 m-auto">
            <h1 class="heading">World best customer support portal</h1>
            <p class="sub-heading">
              deski helps teams of all sizes get better at delivering effortless
              customer service experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: "FancyHero",
};
</script>
<template>
  <div class="integrated-apps mt-180 md-pt-120">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 m-auto">
          <div
            class="title-style-fourteen text-center mb-70 md-mb-50"
            data-aos="fade-up"
          >
            <div class="upper-title">INTEGRATION</div>
            <h2>Integrate with 120+ apps & boost your workfrlow.</h2>
          </div>
        </div>
        <div class="col-xl-8 m-auto">
          <ul
            class="row align-items-center justify-content-center justify-content-lg-between"
          >
            <li class="mt-20 ms-3 me-3 w-auto" data-aos="fade-up">
              <img src="../../assets/images/logo/16.png" alt="" />
            </li>
            <li
              class="mt-20 ms-3 me-3 w-auto"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img src="../../assets/images/logo/logo-33.png" alt="" />
            </li>
            <li
              class="mt-20 ms-3 me-3 w-auto"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <img src="../../assets/images/logo/11.png" alt="" />
            </li>
            <li
              class="mt-20 ms-3 me-3 w-auto"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <img src="../../assets/images/logo/19.png" alt="" />
            </li>
            <li
              class="mt-20 ms-3 me-3 w-auto"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <img src="../../assets/images/logo/18.png" alt="" />
            </li>
            <li
              class="mt-20 ms-3 me-3 w-auto"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <img src="../../assets/images/logo/22.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntegratedApps",
};
</script>

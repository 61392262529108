<template>
  <Portfolio_v1_Main/>
</template>

<script>
import Portfolio_v1_Main from '../components/portfolio-v1';

export default {
  name:'portfolio_v1',
  components:{
    Portfolio_v1_Main,
  }
}
</script>
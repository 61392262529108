<template>
  <div class="fancy-text-block-twelve mt-170 md-mt-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
          <div class="text-wrapper">
            <div class="title-style-three">
              <h6>Why us?</h6>
              <h2>
                <span>Why your should<img src="../../assets/images/shape/line-shape-6.svg" alt=""></span>
                choose deski.
              </h2>
            </div>
            <p class="sub-text pb-35">A ticketing system is a tool, primarily for customer service. It helps customer
              representativ inquiries from one interface without any struggles.</p>
            <ul class="list-item-one">
              <li>Amazing communication.</li>
              <li>Best trendinf designing experience.</li>
              <li>Email & Live chat.</li>
            </ul>
          </div> <!-- /.text-wrapper -->
        </div>

        <div class="col-lg-5 ms-auto" data-aos="fade-left" data-aos-duration="1200">
          <div class="img-gallery">
            <img src="../../assets/images/media/img_37.png" alt="">
            <img src="../../assets/images/shape/53.svg" alt="" class="shapes shape-one">
            <img src="../../assets/images/shape/52.svg" alt="" class="shapes shape-two">
          </div> <!-- /.img-gallery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockTwo'
}
</script>
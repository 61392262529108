<template>
  <EventMain/>
</template>

<script>
import EventMain from '../components/Event';

export default {
  name:'EventPage',
  components:{
    EventMain
  }
}
</script>
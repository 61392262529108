<template>
  <Portfolio_v5_Main/>
</template>

<script>
import Portfolio_v5_Main from '../components/portfolio-v5';

export default {
  name:'Portfolio_v5',
  components:{
    Portfolio_v5_Main,
  }
}
</script>
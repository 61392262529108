<template>
  <div class="fancy-text-block-seven mt-130 md-mt-80">
    <div class="bg-wrapper no-bg">
      <img src="../../assets/images/shape/29.svg" alt="" class="shapes shape-one">
      <img src="../../assets/images/shape/30.svg" alt="" class="shapes shape-two">
      <img src="../../assets/images/shape/31.svg" alt="" class="shapes shape-three">
      <img src="../../assets/images/shape/32.svg" alt="" class="shapes shape-four">
      <img src="../../assets/images/shape/33.svg" alt="" class="shapes shape-five">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-10 m-auto" data-aos="fade-right" data-aos-duration="1200">
            <div class="img-holder">
              <img src="../../assets/images/media/img_108.png" alt="">
            </div>
          </div>
          <div class="col-xl-6 col-lg-7 ms-auto" data-aos="fade-left" data-aos-duration="1200">
            <div class="quote-wrapper pt-60">
              <img src="../../assets/images/icon/185.png" alt="" class="icon">
              <blockquote class="font-rubik">
                Deski combines excellent live chat, ticketing and automation that allow us to provide quality.
              </blockquote>
              <h6 class="mt-20">Mike Lucas. <span>CEO & Founder deksi</span></h6>
            </div> <!-- /.quote-wrapper -->
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'TextBlock'
}
</script>
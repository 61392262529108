<template>
  <WebsiteBuilderMain/>
</template>

<script>
import WebsiteBuilderMain from '../components/website-builder';

export default {
  name:'WebsiteBuilder',
  components:{
    WebsiteBuilderMain,
  }
}
</script>
<template>
  <div
    class="fancy-feature-twentyFive lg-container pt-200 md-pt-100"
    id="feature"
  >
    <div class="container">
      <div class="block-style-twentyFive">
        <div class="row align-items-center">
          <div
            class="col-xl-7 col-lg-6 col-md-10 m-auto"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div class="screen-container">
              <img src="../../assets/images/assets/screen_24.png" alt="" />
            </div>
            <!-- /.screen-container -->
          </div>
          <div
            class="col-xl-5 col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="text-wrapper ps-xl-5">
              <h6>Over <span>150,000+ client</span></h6>
              <h3 class="title">Award winning application.</h3>
              <p>
                Commonly used in the graphic, prit quis & publishing indust for
                previewing lorem visual mockups.
              </p>
            </div>
            <!-- /.text-wrapper -->
          </div>
        </div>
      </div>
      <!-- /.block-style-twentyFive -->

      <div class="block-style-twentyFive mt-200 md-mt-120">
        <div class="row align-items-center">
          <div
            class="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="screen-container">
              <img
                src="../../assets/images/shape/bg6.svg"
                alt=""
                class="ms-auto bg-round-shape"
              />
              <div class="block-content">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <div class="feature-meta">
                      <div class="icon d-flex align-items-end">
                        <img src="../../assets/images/icon/136.svg" alt="" />
                      </div>
                      <h4>
                        Friendly <br />
                        user interface
                      </h4>
                    </div>
                    <!-- /.feature-meta -->
                  </div>
                  <div class="col-sm-6">
                    <div class="feature-meta">
                      <div class="icon d-flex align-items-end">
                        <img src="../../assets/images/icon/137.svg" alt="" />
                      </div>
                      <h4>SEO <br />Optimization</h4>
                    </div>
                    <!-- /.feature-meta -->
                    <div class="feature-meta">
                      <div class="icon d-flex align-items-end">
                        <img src="../../assets/images/icon/138.svg" alt="" />
                      </div>
                      <h4>Quality & fast <br />support</h4>
                    </div>
                    <!-- /.feature-meta -->
                  </div>
                </div>
              </div>
              <!-- /.block-content -->
            </div>
            <!-- /.screen-container -->
          </div>
          <div
            class="col-xl-5 col-lg-6"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div class="text-wrapper order-lg-first">
              <h6>Best Solution</h6>
              <h3 class="title">Why you choose desk app?</h3>
              <p>Things go wrong have We’ve understand.</p>
              <ul>
                <li>Amazing communication.</li>
                <li>Best trendinf designing experience.</li>
                <li>Email & Live chat.</li>
              </ul>
            </div>
            <!-- /.text-wrapper -->
          </div>
        </div>
      </div>
      <!-- /.block-style-twentyFive -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeatureTwo",
};
</script>

<template>
  <div class="fancy-feature-forty lg-container pt-225 md-pt-120" id="product">
    <div class="container">
      <div class="block-style-thirtyEight mb-200 md-mb-150">
        <div class="row align-items-center">
          <div class="col-xl-5 col-lg-6" data-aos="fade-right">
            <div class="title-style-sixteen">
              <div class="upper-title">Easy to use,</div>
              <h2>
                READY, <br />
                HEADSET, GO
              </h2>
            </div>
            <p class="mt-30 pe-xl-5">
              No wires means you're not stuck playing Quest 2 at home. You’re
              free to take all the games, workouts, shows, experiences and more
              on the road with you.
            </p>
          </div>
          <div
            class="col-xl-7 col-lg-5 col-md-8 ms-auto text-end"
            data-aos="fade-left"
          >
            <div class="img-meta d-inline-block position-relative md-mt-70">
              <img src="../../assets/images/media/img_120.jpg" alt="" />
              <img
                src="../../assets/images/shape/244.svg"
                alt=""
                class="shapes shape-one"
                data-aos="fade-down"
                data-aos-delay="300"
              />
              <img
                src="../../assets/images/media/img_121.png"
                alt=""
                class="shapes shape-two"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /.block-style-thirtyEight -->

      <div class="block-style-thirtyEight">
        <div class="row align-items-center">
          <div
            class="col-xl-5 col-lg-6 order-lg-last ms-auto"
            data-aos="fade-left"
          >
            <div class="title-style-sixteen">
              <div class="upper-title">Gaming</div>
              <h2>PLAY FAVORITES GAMES.</h2>
            </div>
            <p class="mt-30 pe-xl-5">
              From multiplayer games to unique experiences to joining up with
              friends at a live show or instructor-led workout, opportunities to
              meet & connect with others.
            </p>
          </div>
          <div class="col-xl-7 col-lg-5 col-md-8" data-aos="fade-right">
            <div class="img-meta d-inline-block position-relative md-mt-70">
              <img src="../../assets/images/media/img_122.jpg" alt="" />
              <img
                src="../../assets/images/media/img_123.png"
                alt=""
                class="shapes shape-three"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /.block-style-thirtyEight -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeatureTwo",
};
</script>

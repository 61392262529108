<template>
  <AppointmentScheduleMain/>
</template>

<script>
import AppointmentScheduleMain from '../components/appointment-schedule';

export default {
  name:'AppointmentSchedule',
  components:{
    AppointmentScheduleMain,
  }
}
</script>
<template>
  <div class="fancy-short-banner-eleven">
    <div class="container">
      <div class="border-bottom pt-130 pb-150 md-pt-80 md-pb-80">
        <div class="row">
          <div class="col-lg-8 col-md-9 m-auto">
            <h2 class="font-rubik" data-aos="fade-up" data-aos-duration="1200">
              Want to be a part of our talented team?
            </h2>
            <router-link to="/contact-us-cs" class="theme-btn-one btn-lg mt-50 md-mt-30" data-aos="fade-up"
              data-aos-duration="1200" data-aos-delay="100">SEND YOUR CV</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShortBanner'
}
</script>
<template>
  <div class="contact-us-light pt-140 pb-200 md-pt-90 md-pb-80">
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-4 col-sm-6">
          <div class="address-info">
            <div class="icon"><img src="../../assets/images/icon/15.svg" alt=""></div>
            <div class="title">Location</div>
            <p class="font-rubik">Dhaka, Kawran Bazar <br> 1201 Metro</p>
          </div> <!-- /.address-info -->
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="address-info">
            <div class="icon"><img src="../../assets/images/icon/16.svg" alt=""></div>
            <div class="title">Contact</div>
            <p class="font-rubik">bawejkor@duwvude.gov <br>(779) 564-1593</p>
          </div> <!-- /.address-info -->
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="address-info">
            <div class="icon"><img src="../../assets/images/icon/17.svg" alt=""></div>
            <div class="title">Social Media</div>
            <p class="font-rubik">Follow on social media</p>
            <ul class="d-flex justify-content-center">
              <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
              <li><a href="#"><i class="fa fa-pinterest" aria-hidden="true"></i></a></li>
            </ul>
          </div> <!-- /.address-info -->
        </div>
      </div>

      <div class="form-style-light">
        <form @submit.prevent="onSubmit" id="contact-form" data-bs-toggle="validator">
          <div class="messages"></div>
          <div class="row controls">
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>First Name</label>
                <input type="text" placeholder="Michel" name="Fname" required="required" 
                data-error="Name is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group-meta form-group mb-35">
                <label>Last Name</label>
                <input type="text" placeholder="Simon" name="Lname" required="required" 
                data-error="Name is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group mb-35">
                <label>Your Email</label>
                <input type="email" placeholder="gobapubo@jogi.net" name="email" required="required"
                  data-error="Valid email is required.">
                <span class="placeholder_icon valid-sign">
                  <img src="../../assets/images/icon/18.svg" alt="">
                </span>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group-meta form-group lg mb-35">
                <label>Your Message</label>
                <textarea placeholder="Write your message here..." name="message" required="required"
                  data-error="Please,leave us a message."></textarea>
                <div class="help-block with-errors"></div>
              </div>
            </div>
            <div class="col-12"><button class="theme-btn-one btn-lg">Send Message</button></div>
          </div>
        </form>
      </div> <!-- /.form-style-light -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactStyleLight',
  methods: {
    onSubmit() {
      console.log('Submitted');
    },
  },
}
</script>
<template>
  <div class="fancy-text-block-nineteen mt-250 mb-200 md-mt-150 md-mb-150">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
          <img src="../../assets/images/media/img_57.png" alt="" class="m-auto" />
        </div>
        <div class="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
          <div class="text-wrapper">
            <div class="client-info font-rubik">
              Over <span>150,000+ client</span>
            </div>
            <div class="title-style-five">
              <h2><span>Best event & online</span> ticket platform.</h2>
            </div>
            <p class="font-rubik">
              Things go wrong. You’ll have questions. We understand. So we have
              people, not bots, on hand to help.
            </p>
            <p class="font-rubik">
              We aim to answer any query in less than 10 minutes.
            </p>
            <div class="name font-slab">Mitchell Garner</div>
          </div>
          <!-- /.text-wrapper -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
};
</script>
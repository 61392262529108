<template>
  <div class="useable-tools-section-four lg-container mt-200 md-mt-100">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="text-wrapper">
              <div class="title-style-nine">
                <h2>Integrate Any Tools Seamlessly.</h2>
              </div>
              <p>Commonly used in the graphic, print quis & publishing industris for previewing nor visual layout and
                mockups.</p>
              <a href="#" class="theme-btn-ten">See all integrations <i class="fa fa-chevron-right"
                  aria-hidden="true"></i></a>
            </div> <!-- /.text-wrapper -->
          </div>
          <div class="col-xl-5 col-lg-6 ms-auto">
            <div class="logo-container h-100">
              <div class="inner-wrapper">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="line-one">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-47.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-48.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-49.png" alt="">
                      </div> <!-- /.icon -->
                    </div> <!-- /.line-one -->
                  </div>
                  <div class="col-sm-6">
                    <div class="line-two">
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-50.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-51.png" alt="">
                      </div> <!-- /.icon -->
                      <div class="icon d-flex align-items-center justify-content-center">
                        <img src="../../assets/images/logo/logo-52.png" alt="">
                      </div> <!-- /.icon -->
                    </div> <!-- /.line-two -->
                  </div>
                </div>
              </div> <!-- /.inner-wrapper -->
            </div> <!-- /.logo-container -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UseableTools'
}
</script>
<template>
  <div class="fancy-short-banner-fourteen mt-150 md-mt-80">
    <div class="container">
      <div class="row">
        <div class="col-12" data-aos="fade-up" data-aos-duration="1200">
          <div class="title-style-thirteen">
            <h2>200k+ Customers <br> have our deski.Try it now!</h2>
          </div>
          <p>Try it risk free — we don’t charge cancellation fees.</p>
          <router-link to="/register" class="theme-btn-twelve">Let’s Get Started</router-link>
        </div>
      </div>
    </div> <!-- /.container -->
    <img src="../../assets/images/shape/239.svg" alt="" class="shapes shape-one">
    <img src="../../assets/images/shape/240.svg" alt="" class="shapes shape-two">
    <img src="../../assets/images/shape/232.svg" alt="" class="shapes shape-three">
    <img src="../../assets/images/shape/233.svg" alt="" class="shapes shape-four">
    <img src="../../assets/images/shape/234.svg" alt="" class="shapes shape-five">
    <img src="../../assets/images/shape/235.svg" alt="" class="shapes shape-six">
    <img src="../../assets/images/shape/236.svg" alt="" class="shapes shape-seven">
    <img src="../../assets/images/shape/232.svg" alt="" class="shapes shape-eight">
  </div>
</template>

<script>
export default {
  name: 'ShortBanner'
}
</script>
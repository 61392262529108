<template>
  <AboutDocMain/>
</template>

<script>
import AboutDocMain from '../components/about-us(doc)';

export default {
  name:'AboutDoc',
  components:{
    AboutDocMain,
  }
}
</script>
<template>
  <VideoEditorMain/>
</template>

<script>
import VideoEditorMain from '@/components/video-editor/index.vue';

export default {
  name:'VideoEditor',
  components:{
    VideoEditorMain,
  }
}
</script>
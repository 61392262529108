<template>
  <MobileAppMain/>
</template>

<script>
import MobileAppMain from '../components/mobile-app';

export default {
  name:'MobileApp',
  components:{
    MobileAppMain,
  }
}
</script>
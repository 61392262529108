<template>
  <DocFullWidthMain/>
</template>

<script>
import DocFullWidthMain from '../components/doc-fullwidth';

export default {
  name:'DocFullWidth',
  components:{DocFullWidthMain}
}
</script>
<template>
  <div class="fancy-feature-sixteen pt-200 md-pt-100" id="feature">
    <div class="container">
      <div class="block-style-eighteen">
        <div class="row align-items-center">
          <div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
            <div class="text-wrapper">
              <h6>App Integration</h6>
              <h3 class="title">Connect with <span>software.</span></h3>
              <p>
                Share content across apps. Deski connects with the productivity
                tools you already use, so you can work your way.
              </p>
              <a href="#" class="d-flex align-items-center learn-more">
                <span>Learn More</span>
                <img src="../../assets/images/icon/93.svg" alt="" />
              </a>
            </div>
            <!-- /.text-wrapper -->
          </div>
          <div class="col-lg-7" data-aos="fade-left" data-aos-duration="1200">
            <div
              class="screen-holder-one d-flex align-items-center justify-content-center"
            >
              <div
                class="round-bg d-flex align-items-center justify-content-center"
                style="width: 193px; height: 193px"
              >
                <img src="../../assets/images/logo/logo-26.png" alt="" />
              </div>
              <div
                class="round-bg d-flex align-items-center justify-content-center shapes logo-one"
                style="width: 65px; height: 65px"
              >
                <img src="../../assets/images/logo/logo-27.png" alt="" />
              </div>
              <div
                class="round-bg d-flex align-items-center justify-content-center shapes logo-two"
                style="width: 105px; height: 105px"
              >
                <img src="../../assets/images/logo/logo-28.png" alt="" />
              </div>
              <div
                class="round-bg d-flex align-items-center justify-content-center shapes logo-three"
                style="width: 81px; height: 81px"
              >
                <img src="../../assets/images/logo/logo-29.png" alt="" />
              </div>
              <div
                class="round-bg d-flex align-items-center justify-content-center shapes logo-four"
                style="width: 148px; height: 148px"
              >
                <img src="../../assets/images/logo/logo-30.png" alt="" />
              </div>
              <div
                class="round-bg d-flex align-items-center justify-content-center shapes logo-five"
                style="width: 105px; height: 105px"
              >
                <img src="../../assets/images/logo/logo-31.png" alt="" />
              </div>
              <img
                src="../../assets/images/shape/170.svg"
                alt=""
                class="shapes shape-one"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /.block-style-eighteen -->

      <div class="block-style-eighteen mt-200 pt-50 md-mt-80">
        <div class="row align-items-center">
          <div
            class="col-lg-5 order-lg-last"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="text-wrapper">
              <h6>Scanning & Doc</h6>
              <h3 class="title">
                Web doc <br />
                file <span>Scanning.</span>
              </h3>
              <p>
                Go paperless. Back up important documents to all your devices,
                and keep the information not the clutter.
              </p>
              <a href="#" class="d-flex align-items-center learn-more">
                <span>Learn More</span>
                <img src="../../assets/images/icon/93.svg" alt="" />
              </a>
            </div>
            <!-- /.text-wrapper -->
          </div>
          <div
            class="col-lg-7 order-lg-first"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div class="screen-holder-two">
              <img src="../../assets/images/assets/screen_12.png" alt="" />
              <img
                src="../../assets/images/assets/screen_13.png"
                alt=""
                class="shapes screen-one"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /.block-style-eighteen -->

      <div class="block-style-eighteen mt-200 pt-50 md-mt-80">
        <div class="row align-items-center">
          <div class="col-lg-5" data-aos="fade-right" data-aos-duration="1200">
            <div class="text-wrapper">
              <h6>Note & Docs</h6>
              <h3 class="title"><span>Clipper</span> & Rich notes</h3>
              <p>
                Save web pages (without the ads) and mark them up with arrows,
                highlights, and text to make them more useful.
              </p>
              <a href="#" class="d-flex align-items-center learn-more">
                <span>Learn More</span>
                <img src="../../assets/images/icon/93.svg" alt="" />
              </a>
            </div>
            <!-- /.text-wrapper -->
          </div>
          <div class="col-lg-7" data-aos="fade-left" data-aos-duration="1200">
            <div
              class="screen-holder-three d-flex align-items-center justify-content-center"
            >
              <img src="../../assets/images/assets/screen_14.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- /.block-style-eighteen -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeatureTwo",
};
</script>

<template>
  <div class="fancy-short-banner-six pt-150 md-pt-80">
    <img
      src="../../assets/images/shape/143.svg"
      alt=""
      class="shapes shape-one"
    />
    <div class="container">
      <div class="row">
        <div
          class="col-xl-9 col-lg-11 m-auto"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="title-style-six text-center">
            <h2>
              love our product? <br /><span>Save $20</span> by grab it today.
            </h2>
          </div>
          <!-- /.title-style-six -->
        </div>
      </div>
      <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
        Try it risk free — we don’t charge cancellation fees.
      </p>
      <a
        href="#"
        class="theme-btn-seven"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
        >Purchase Now</a
      >
    </div>
    <!-- /.container -->
  </div>
</template>

<script>
export default {
  name: "ShortBanner",
};
</script>

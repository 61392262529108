<template>
  <ContactManagementMain/>
</template>

<script>
import ContactManagementMain from '../components/contact-us(management)';

export default {
  name:'ContactManagement',
  components:{ContactManagementMain}
}
</script>
<template>
  <DocSignatureMain/>
</template>

<script>
import DocSignatureMain from '../components/doc-signature';

export default {
  name:'DocSignature',
  components:{
    DocSignatureMain,
  }
}
</script>
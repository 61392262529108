<template>
  <div class="fancy-text-block-thirteen pt-140 md-pt-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 order-lg-last" data-aos="fade-left" data-aos-duration="1200">
          <div class="text-wrapper">
            <div class="title-style-two">
              <h2>Security you can trust easily.</h2>
            </div> <!-- /.title-style-two -->
            <p>Our dashboard is served over SSL which means you can be sure that all data transferred is secure. Payment
              processing uses the very latest PCI DSS card-security standards</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-8 m-auto order-lg-first" data-aos="fade-right" data-aos-duration="1200">
          <img src="../../assets/images/assets/feature-img-11.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlock'
}
</script>
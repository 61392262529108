<template>
  <Services_v4_Main/>
</template>

<script>
import Services_v4_Main from '../components/services-v4';

export default {
  name:'Services_v4',
  components:{Services_v4_Main}
}
</script>
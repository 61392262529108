<template>
  <Team_v2_Main/>
</template>

<script>
import Team_v2_Main from '../components/team-v2';

export default {
  name:'Team_v2',
  components:{
    Team_v2_Main,
  }
}
</script>
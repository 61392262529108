<template>
  <div
    class="pricing-section-five pt-100 pt-150 md-pt-150 sm-pt-80 md-pt-80"
    id="pricing"
  >
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 order-lg-last">
          <div class="title-style-six ps-5 md-p0">
            <h6 style="font-size: 15px">Our Pricing</h6>
            <h2>Choose your <span>Product.</span></h2>
            <p class="text-xs">
              Lorem ipsum dolor on adipisci elit sed do eiusmod liu.
            </p>
          </div>
          <!-- /.title-style-six -->
        </div>
        <div class="col-lg-7 position-relative">
          <div class="pricing-table-area-five md-mt-60">
            <img
              src="../../assets/images/icon/88.png"
              alt=""
              class="shapes shape-one"
            />
            <img
              src="../../assets/images/shape/160.png"
              alt=""
              class="shapes shape-two"
            />
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="pr-table-wrapper active js-tilt">
                  <img
                    src="../../assets/images/icon/85.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">Dimond Plan</div>
                  <ul class="pr-feature">
                    <li>Unlimited Email</li>
                    <li>5gb Hosting & Domain</li>
                    <li>Email & Live chat.</li>
                  </ul>
                  <div class="price">$59.99</div>
                  <div class="trial-text">up to 10 user + 1.99 per user</div>
                  <a href="#" class="trial-button hover-reverse-gr-bg-one"
                    >Sign up</a
                  >
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
              <div class="col-md-6">
                <div class="pr-table-wrapper js-tilt sm-mt-80">
                  <img
                    src="../../assets/images/shape/159.svg"
                    alt=""
                    class="popular-badge"
                  />
                  <img
                    src="../../assets/images/icon/86.svg"
                    alt=""
                    class="icon"
                  />
                  <div class="pack-name">Gold Plan</div>
                  <ul class="pr-feature">
                    <li>Unlimited Email</li>
                    <li>5gb Hosting</li>
                    <li>Email & Live chat.</li>
                    <li>3 Domain</li>
                  </ul>
                  <div class="price">$29.99</div>
                  <div class="trial-text">up to 7 user + 1.99 per user</div>
                  <a
                    href="#"
                    class="trial-button hover-reverse-gr-bg-one gr-bg-one"
                    >Sign up</a
                  >
                </div>
                <!-- /.pr-table-wrapper -->
              </div>
            </div>
          </div>
          <!-- /.pricing-table-area-five -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingArea",
};
</script>

<template>
  <div class="fancy-text-block-nine mt-130 md-mt-80">
    <div class="shapes shape-one"></div>
    <div class="shapes shape-two"></div>
    <div class="shapes shape-three"></div>
    <div class="shapes shape-four"></div>
    <div class="container">
      <div class="title-style-two text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col-lg-10 m-auto">
            <p>Our Story</p>
            <h2>The world’s first Conversational Relationship Platform</h2>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-10 m-auto">
          <p class="text-meta" data-aos="fade-up" data-aos-duration="1200">
            We created Help deski for businesses that share our passion for
            doing right by the customer. For many of us, great customer service
            isn’t a cost center — it’s an effective marketing tool, a
            competitive differentiator, and a cornerstone of the brand.
          </p>
          <p
            class="text-meta"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            But in 2008, there were no customer service platforms available that
            embodied our customer-centric values. There were help desks that
            quite literally treated each person like a number and made it far
            too difficult to build a long-term relationship. We felt a strong
            pull to make something different, and we did.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyTextBlock",
};
</script>
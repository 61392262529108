<template>
  <div class="fancy-text-block-fourteen pt-30">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
          <div class="text-wrapper">
            <div class="title-style-two">
              <p>About us</p>
              <h2>Best event & online ticket platform.</h2>
            </div> <!-- /.title-style-two -->
            <p class="sub-text">Things go wrong. You’ll have questions. We understand. So we have real people, not bots,
              on hand to help, 7 days a week.</p>
            <p class="sub-text">We aim to answer any query in less than 10 minutes. Try now</p>
            <div class="name">Mr Rashed Kabir, <span>CEO</span></div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
          <div class="img-holder md-mt-80">
            <img src="../../assets/images/media/img_39.png" alt="">
            <img src="../../assets/images/shape/60.svg" alt="" class="shapes shape-one">
            <img src="../../assets/images/shape/61.svg" alt="" class="shapes shape-two">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutArea'
}
</script>
<template>
  <ProductLandingMain />
</template>

<script>
import ProductLandingMain from "../components/product-landing";

export default {
  name: "ProductLanding",
  components: {
    ProductLandingMain,
  },
};
</script>

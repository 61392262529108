<template>
  <div class="fancy-hero-one">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 m-auto">
          <h2 class="font-rubik">We’ve Most Talented Team Members</h2>
        </div>
        <div class="col-xl-9 col-lg-11 m-auto">
          <p class="font-rubik">With deski, get all kind of business solution to start your journey</p>
          <router-link to="/contact-us-cs" class="theme-btn-one btn-lg mt-50 md-mt-30">Contact US</router-link>
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>
<template>
  <div class="useable-tools-section-three pt-250 mb-200 md-pt-120 md-mb-80">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="title-style-ten">
            <h6>Our Partners</h6>
            <h2>Who is using deski.</h2>
          </div>
          <p class="sub-text">
            Share content across apps. Evernote connects with the productivity
            tools you already use, so you can work your way.
          </p>
        </div>
      </div>
    </div>
    <!-- /.container -->
    <div
      class="logo-wrapper d-flex flex-wrap justify-content-center align-items-center"
    >
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-32.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-33.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-34.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-35.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-36.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-37.png" alt="" />
      </div>
      <div
        class="logo bg-white d-flex align-items-center justify-content-center"
      >
        <img src="../../assets/images/logo/logo-38.png" alt="" />
      </div>
    </div>
    <!-- /.logo-wrapper -->
  </div>
</template>

<script>
export default {
  name: "OurPartners",
};
</script>

<template>
  <div class="fancy-text-block-one">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-7 col-sm-8 m-auto">
          <img src="../../assets/images/media/img_05.png" alt="">
        </div>
        <div class="col-lg-6">
          <div class="quote-wrapper">
            <p>More over <span>1500+ customers</span></p>
            <blockquote class="font-rubik">
              Just simply amazing. Feel lucky use their service.Highly recommended andappriciate their service & highly trusted.
            </blockquote>
            <h6>Jimmy Klein. <span>California USA</span></h6>
          </div> <!-- /.quote-wrapper -->
        </div>
      </div>
    </div>
    <div class="bubble-one"></div>
    <div class="bubble-two"></div>
    <div class="bubble-three"></div>
    <div class="bubble-four"></div>
    <div class="bubble-five"></div>
    <div class="bubble-six"></div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlock'
}
</script>
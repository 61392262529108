<template>
  <div class="fancy-text-block-ten mt-120 md-mt-60">
    <div class="container">
      <div class="row align-items-end">
        <div class="col-xl-5 col-lg-6 ms-auto order-lg-last" data-aos="fade-left" data-aos-duration="1200">
          <img src="../../assets/images/icon/34.svg" alt="" class="icon">
          <p class="font-rubik">Deski combines excellent live chat, ticketing and automation that allow us to provide
            quality.</p>
          <div class="name sm-mb-40">Mike Lucas. <span>CEO & Founder <br>deksi</span></div>
        </div>
        <div class="col-lg-6 order-lg-first">
          <div class="row align-items-end">
            <div class="col-6" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100">
              <img src="../../assets/images/media/img_25.png" alt="" class="img-meta"></div>
            <div class="col-6" data-aos="fade-right" data-aos-duration="1200">
              <img src="../../assets/images/media/img_26.png" alt="" class="img-meta">
              </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-10 ms-auto">
          <div class="img-gallery mt-60 md-mt-20">
            <div class="row">
              <div class="col-sm-5 sm-mb-20" data-aos="fade-up" data-aos-duration="1200">
                <img src="../../assets/images/media/img_27.png" alt="" class="img-meta"></div>
              <div class="col-sm-7" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                <img src="../../assets/images/media/img_28.png" alt="" class="img-meta"></div>
            </div>
          </div> <!-- /.img-gallery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockTwo'
}
</script>
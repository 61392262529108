<template>
  <ProductCsMain/>
</template>

<script>
import ProductCsMain from '../components/product-cs';

export default {
  name:'ProductCs',
  components:{ProductCsMain},
}
</script>
<template>
  <FeatureCsMain/>
</template>

<script>
import FeatureCsMain from '../components/feature-cs';

export default {
  name:'FeatureCs',
  components:{
    FeatureCsMain,
  }
}
</script>
<template>
  <AboutUsEventMain/>
</template>

<script>
import AboutUsEventMain from '../components/about-us(event)';

export default {
  name:'AboutUsEvent',
  components:{
    AboutUsEventMain,
  }
}
</script>
<template>
  <div class="main-page-wrapper p0 overflow-hidden">
    <SignUpArea/>
  </div>
</template>

<script>
import SignUpArea from './sign-up-area.vue';

export default {
  components: { SignUpArea },
  name:'SignUpMain'
}
</script>
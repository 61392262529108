<template>
  <div class="fancy-hero-seven banner">
    <div class="container position-relative">
      <div class="row">
        <div class="col-xl-8 col-lg-10 m-auto">
          <h2 class="font-gordita">Our Services</h2>
          <p class="font-gordita">We are a creative company that focuses on establishing long-term relationships with customers.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroBanner'
}
</script>

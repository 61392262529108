<template>
  <div class="block-style-thirtySix lg-container pt-225 md-pt-120">
    <div class="container">
      <div class="row align-items-center">
        <div
          class="col-xl-5 col-lg-6 ms-auto order-lg-last"
          data-aos="fade-left"
        >
          <div class="title-style-fourteen">
            <div class="upper-title">GREAT INTERFACE</div>
            <h2>First-class & smooth user experience.</h2>
          </div>
          <div class="tag-line pb-10">
            Deski responds to previous answers to show only the most relevant
            questions. A better experience for respondents better data for you.
          </div>
          <a href="#" class="theme-btn-fourteen">Create survay now </a>
        </div>
        <div
          class="col-lg-6 order-lg-first text-center text-lg-start"
          data-aos="fade-right"
        >
          <div
            class="illustration-holder-two d-inline-block ps-4 ps-md-5 md-mt-90"
          >
            <img
              src="../../assets/images/assets/screen_38.png"
              alt=""
              class="w-100"
            />
            <img
              src="../../assets/images/assets/screen_38.1.png"
              alt=""
              class="shapes shape-one"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockStyleTwo",
};
</script>

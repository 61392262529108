<template>
  <SolutionManagementMain/>
</template>

<script>
import SolutionManagementMain from '../components/solution(managment)';

export default {
  name:'SolutionManagement',
  components:{SolutionManagementMain}
}
</script>
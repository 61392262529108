<template>
  <PricingCsMain/>
</template>

<script>
import PricingCsMain from '../components/pricing(cs)';

export default {
  name:'PricingCs',
  components:{
    PricingCsMain,
  }
}
</script>
<template>
  <div class="partner-section-one mt-170 md-pt-90 pb-120 md-pb-80">
    <div class="container">
      <div class="title-style-nine text-center mb-100">
        <h6>Our Partners</h6>
        <h2>
          They
          <span
            >Trust Us, <img src="../../assets/images/shape/201.svg" alt=""
          /></span>
          & Vice Versa
        </h2>
      </div>

      <div class="row justify-content-center">
        <div
          class="col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="img-box bx-a">
            <a href="#"
              ><img src="../../assets/images/logo/p-1.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-lg-2 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
          <div class="img-box bx-b">
            <a href="#"
              ><img src="../../assets/images/logo/p-2.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div class="img-box bx-c">
            <a href="#"
              ><img src="../../assets/images/logo/p-3.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-2 col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="150"
        >
          <div class="img-box bx-d">
            <a href="#"
              ><img src="../../assets/images/logo/p-4.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-2 col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div class="img-box bx-e">
            <a href="#"
              ><img src="../../assets/images/logo/p-5.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-4 col-lg-2 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="250"
        >
          <div class="img-box bx-f">
            <a href="#"
              ><img src="../../assets/images/logo/p-6.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-2 col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          <div class="img-box bx-g">
            <a href="#"
              ><img src="../../assets/images/logo/p-7.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-3 col-lg-3 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="350"
        >
          <div class="img-box bx-h">
            <a href="#"
              ><img src="../../assets/images/logo/p-8.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
        <div
          class="col-xl-3 col-lg-11 col-md-4 col-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="400"
        >
          <div class="img-box bx-i">
            <a href="#"
              ><img src="../../assets/images/logo/p-9.png" alt=""
            /></a>
          </div>
          <!-- /.img-box -->
        </div>
      </div>
    </div>
    <img
      src="../../assets/images/shape/202.svg"
      alt=""
      class="shapes shape-one"
    />
    <img
      src="../../assets/images/shape/203.svg"
      alt=""
      class="shapes shape-two"
    />
    <img
      src="../../assets/images/shape/204.svg"
      alt=""
      class="shapes shape-three"
    />
    <img
      src="../../assets/images/shape/205.svg"
      alt=""
      class="shapes shape-four"
    />
    <img
      src="../../assets/images/shape/206.svg"
      alt=""
      class="shapes shape-five"
    />
    <img
      src="../../assets/images/shape/207.svg"
      alt=""
      class="shapes shape-six"
    />
    <img
      src="../../assets/images/shape/208.svg"
      alt=""
      class="shapes shape-seven"
    />
    <img
      src="../../assets/images/shape/209.svg"
      alt=""
      class="shapes shape-eight"
    />
  </div>
</template>

<script>
export default {
  name: "PartnersSection",
};
</script>

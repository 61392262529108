<template>
  <DocFullWidthBannerMain/>
</template>

<script>
import DocFullWidthBannerMain from '../components/doc-full-width-banner';

export default {
  name:'DocFullWidthBanner',
  components:{DocFullWidthBannerMain}
}
</script>
<template>
  <div class="fancy-text-block-two pt-150 pb-170 md-pt-100 md-pb-120">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="100">
          <div class="title-style-one md-mb-40">
            <h6 class="font-rubik" style="color:#787CFF">Our product philosophy</h6>
            <h2>We're looking for the brightest minds to us develop the future of work due.</h2>
          </div> <!-- /.title-style-one -->
        </div>
        <div class="col-lg-6 col-md-8 m-auto" data-aos="fade-left" data-aos-duration="1200">
          <img src="../../assets/images/media/img_06.png" alt="" class="fancy_img_media">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlockTwo'
}
</script>
<template>
  <div class="map-area-one mt-170 md-mt-80">
    <div class="si-content">
      <h3>Come say Hi!</h3>
      <p>We're based in Gazipur, a beautiful city just hours from the Dhaka!</p>
      <span>10 Rue Dugas-Montbel, 69002 Lyon</span>
    </div>
    <div class="map-canvas">
      <iframe id="gmap_canvas" width="100%" height="100%" src="https://maps.google.com/maps?q=Mission%20District%2C%20San%20Francisco%2C%20CA%2C%20USA&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MapArea'
}
</script>
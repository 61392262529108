<template>
  <Services_v1_Main/>
</template>

<script>
import Services_v1_Main from '../components/services-v1';

export default {
  name:'Services_v1',
  components:{
    Services_v1_Main,
  }
}
</script>
<template>
  <ComingSoonMain/>
</template>

<script>
import ComingSoonMain from '../components/coming-soon';

export default {
  name:'ComingSoon',
  components:{
    ComingSoonMain,
  }
}
</script>
<template>
  <div class="fancy-short-banner-ten pt-170 md-pt-80">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-9 col-lg-11 m-auto"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div class="text-center">
            <h2>Try Deski Free</h2>
            <p>
              After your 14-day trial of our Professional plan, enjoy the Free
              version of Calendly – forever.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
        <div class="dropdown download-btn">
          <button
            class="dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Get Deski app
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item d-flex align-items-center" href="#">
              <img src="../../assets/images/icon/103.svg" alt="" />
              <span>IOS & Android</span>
            </a>
            <a class="dropdown-item d-flex align-items-center" href="#">
              <img src="../../assets/images/icon/104.svg" alt="" />
              <span>Mac & Windows</span>
            </a>
          </div>
        </div>
        <div class="info-text">No Credit Card Required. Cancel Anytime</div>
      </div>
    </div>
    <!-- /.container -->
    <img
      src="../../assets/images/shape/210.svg"
      alt=""
      class="shapes shape-one"
    />
    <img
      src="../../assets/images/shape/211.svg"
      alt=""
      class="shapes shape-two"
    />
  </div>
</template>

<script>
export default {
  name: "ShortBanner",
};
</script>

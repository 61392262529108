<template>
  <FaqsMain/>
</template>

<script>
import FaqsMain from '../components/faqs';

export default {
  name:'FaqsPage',
  components:{
    FaqsMain
  }
}
</script>
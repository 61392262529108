<template>
  <div class="fancy-feature-twentyThree pt-170 md-pt-100" id="feature">
    <div class="container">
      <div
        class="title-style-nine text-center pb-180 md-pb-100"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <h6>Our Features</h6>
        <h2>
          Deski is all About Easy Bookings, Efficient Service, &
          <span
            >Robust Interface
            <img src="../../assets/images/shape/192.svg" alt=""
          /></span>
        </h2>
        <p>
          Our online booking software allows you to schedule your client’s
          appointments easily & efficiently.
        </p>
      </div>
      <div class="block-style-twentyThree">
        <div class="row align-items-center">
          <div
            class="col-lg-6 order-lg-last ms-auto"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="screen-container ms-auto">
              <div class="oval-shape" style="background: #69ff9c"></div>
              <div class="oval-shape" style="background: #fff170"></div>
              <img
                src="../../assets/images/assets/screen_18.png"
                alt=""
                class="shapes shape-one"
              />
            </div>
            <!-- /.screen-container -->
          </div>
          <div
            class="col-lg-5 order-lg-first"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div class="text-wrapper">
              <h6>One click away</h6>
              <h3 class="title">Make your schedule Online easily.</h3>
              <p>
                From its medieval origins to the digital era, learn everything
                there is to know about the ubiquitep lorem ipsum passage.
              </p>
            </div>
            <!-- /.text-wrapper -->
          </div>
        </div>
      </div>
      <!-- /.block-style-twentyThree -->

      <div class="block-style-twentyThree">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="screen-container me-auto"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div class="oval-shape" style="background: #ffde69"></div>
              <div class="oval-shape" style="background: #ff77d9"></div>
              <img
                src="../../assets/images/assets/screen_19.png"
                alt=""
                class="shapes shape-two"
              />
            </div>
            <!-- /.screen-container -->
          </div>
          <div
            class="col-lg-5 ms-auto"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="text-wrapper">
              <h6>WORKFLOW MANAGEMENT</h6>
              <h3 class="title">Automate reminders & follow-ups.</h3>
              <p>
                Calendly puts your entire meeting workflow on autopilot, sending
                everything from reminder emails to thank you notes, so you can
                focus on the work only you can do.
              </p>
            </div>
            <!-- /.text-wrapper -->
          </div>
        </div>
      </div>
      <!-- /.block-style-twentyThree -->

      <div class="block-style-twentyThree">
        <div class="row">
          <div
            class="col-lg-6 order-lg-last ms-auto"
            data-aos="fade-left"
            data-aos-duration="1200"
          >
            <div class="screen-container ms-auto">
              <div class="oval-shape" style="background: #00f0ff"></div>
              <div class="oval-shape" style="background: #fc6bff"></div>
              <img
                src="../../assets/images/assets/screen_20.png"
                alt=""
                class="shapes shape-three"
              />
            </div>
            <!-- /.screen-container -->
          </div>
          <div
            class="col-lg-5 order-lg-first"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <div class="text-wrapper">
              <h6>ON-DEMAND SCHEDULING</h6>
              <h3 class="title">More connections and no cancellations.</h3>
              <p>
                Prospects can schedule meetings in just a few clicks – whenever
                the moment is right. And cancellations go down because
                rescheduling is easy, fast, and on their terms.
              </p>
            </div>
            <!-- /.text-wrapper -->
          </div>
        </div>
      </div>
      <!-- /.block-style-twentyThree -->
    </div>
  </div>
</template>

<script>
export default {
  name: "FancyFeatureTwo",
};
</script>

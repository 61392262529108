<template>
  <Blog_v4_Main/>
</template>

<script>
import Blog_v4_Main from '../components/blog-v4';

export default {
  name:'Blog_v4',
  components:{Blog_v4_Main}
}
</script>
<template>
  <div class="fancy-hero-five">
    <img src="../../assets/images/shape/95.svg" alt="" class="shapes shape-one">
    <img src="../../assets/images/shape/96.svg" alt="" class="shapes shape-two">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 m-auto text-center">
            <h6 class="page-title">Our News</h6>
            <h1 class="heading">
              <span>Our inside stories<img src="../../assets/images/shape/line-shape-11.svg" alt=""></span>
              lorem que
            </h1>
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>
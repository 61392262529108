<template>
  <div class="hero-banner-ten">
    <div class="container">
      <div class="row">
        <div class="col-xl-9 col-lg-11 col-md-8 m-auto">
          <h1 class="hero-heading">Build your Product by Deski App.</h1>
          <p class="hero-sub-heading">Deski delivered blazing fast performance, striking word solution</p>
        </div>
      </div>
      <div class="d-sm-flex align-items-center justify-content-center button-group">
        <a href="#" class="d-flex align-items-center ios-button">
          <img src="../../assets/images/icon/apple.svg" alt="" class="icon">
          <div>
            <span>Download on the</span>
            <strong>App store</strong>
          </div>
        </a>
        <a href="#" class="d-flex align-items-center windows-button">
          <img src="../../assets/images/icon/playstore.svg" alt="" class="icon">
          <div>
            <span>Get it on</span>
            <strong>Google play</strong>
          </div>
        </a>
      </div>
    </div>
    <div class="icon-box-one"><img src="../../assets/images/logo/logo-39.png" alt=""></div>
    <div class="icon-box-two"><img src="../../assets/images/logo/logo-40.png" alt=""></div>
    <div class="icon-box-three"><img src="../../assets/images/logo/logo-41.png" alt=""></div>
    <div class="icon-box-four"><img src="../../assets/images/logo/logo-42.png" alt=""></div>
    <div class="icon-box-five"><img src="../../assets/images/logo/logo-43.png" alt=""></div>
    <div class="icon-box-six"><img src="../../assets/images/logo/logo-44.png" alt=""></div>
    <div class="icon-box-seven"><img src="../../assets/images/logo/logo-45.png" alt=""></div>
    <div class="icon-box-eight"><img src="../../assets/images/logo/logo-46.png" alt=""></div>
  </div>
</template>

<script>
export default {
  name: 'HeroBanner'
}
</script>
<template>
  <Services_v3_Main/>
</template>

<script>
import Services_v3_Main from '../components/services-v3';

export default {
  name:'Services_v3',
  components:{Services_v3_Main}
}
</script>
<template>
  <div class="fancy-text-block-eleven mt-225 md-mt-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-last">
          <div class="text-wrapper">
            <div class="title-style-two mb-35 md-mb-20">
              <h2><span>Exclusive feature <img src="../../assets/images/shape/line-shape-5.svg" alt=""></span>
                with great offers everytime.
              </h2>
            </div> <!-- /.title-style-two -->
            <p>With Benefits from deski Pro, Earn rewards & Score discounts on purchases* Foryourself and your
              customers.</p>
          </div>
        </div>
        <div class="col-lg-6 order-lg-first">
          <div class="img-gallery">
            <img src="../../assets/images/media/img_35.png" alt="">
            <img src="../../assets/images/media/img_36.png" alt="" class="overlay-img">
            <img src="../../assets/images/shape/49.svg" alt="" class="shapes shape-one">
            <img src="../../assets/images/shape/50.svg" alt="" class="shapes shape-two">
          </div> <!-- /.img-gallery -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FancyTextBlock'
}
</script>
<template>
  <Portfolio_v2_Main/>
</template>

<script>
import Portfolio_v2_Main from '../components/portfolio-v2';

export default {
  name:'Portfolio_v2',
  components:{
    Portfolio_v2_Main
  }
}
</script>
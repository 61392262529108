<template>
  <DocLandingMain/>
</template>

<script>
import DocLandingMain from '../components/doc-landing';

export default {
  name:'DocLanding',
  components:{
    DocLandingMain
  }
}
</script>
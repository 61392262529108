<template>
  <CustomerSupportMain/>
</template>

<script>
import CustomerSupportMain from '../components/customer-support';

export default {
  name:'CustomerSupport',
  components:{
    CustomerSupportMain,
  }
}
</script>
<template>
  <Portfolio_v4_Main/>
</template>

<script>
import Portfolio_v4_Main from '../components/portfolio-v4';

export default {
  name:'Portfolio_v4',
  components:{
    Portfolio_v4_Main,
  }
}
</script>
<template>
  <ContactUsCsMain/>
</template>

<script>
import ContactUsCsMain from '../components/contact-us(cs)';

export default {
  name:'ContactUsCs',
  components:{ContactUsCsMain},
}
</script>
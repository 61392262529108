<template>
  <ContactDocMain/>
</template>

<script>
import ContactDocMain from '../components/contact-us(doc)';

export default {
  name:'ContactDoc',
  components:{ContactDocMain}
}
</script>
<template>
 <FormSurveyMain/>
</template>

<script>
import FormSurveyMain from '../components/form-survey';

export default {
  name:'FormSurvey',
  components:{
    FormSurveyMain,
  }
}
</script>
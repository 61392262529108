<template>
  <TeamMain/>
</template>

<script>
import TeamMain from '../components/team-v1';

export default {
  name:'Team_v1',
  components:{
    TeamMain,
  }
}
</script>